// React
import { useTheme } from "@emotion/react";

// MUI
import { Avatar, Box } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

const UserChatMessage = (props) => {
    const userProps = {
        alt: props.name,
        title: props.name,
        src: props.avatarUrl,
    };

    const theme = useTheme();

    function nameToInitials(name) {
        return name.split(' ').map((s) => s.charAt(0).toUpperCase()).join('');
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: nameToInitials(name),
        };
    }

    return (
        <>
            <Box sx={{
                position: 'relative',
                bgcolor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                padding: theme.spacing(2),
                borderRadius: '0.5rem',
                alignSelf: 'flex-end',
                maxWidth: '80%',
                wordWrap: 'break-word',
                marginRight: '0.5rem !important', // @xxx hack to fix ::after overflowing when avatars are disabled
                '& > p': {
                    margin: 0,
                },
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    width: 0,
                    height: 0,
                    border: '0.5rem solid transparent',
                    borderLeftColor: theme.palette.primary.main,
                    borderRight: 0,
                    transform: 'translate(100%, -' + theme.spacing(1) + ')',
                }
            }}>
                <p>
                    {props.message}
                </p>
            </Box>
            {props.avatar && (
                props.name
                    ?
                        <Avatar {...userProps} {...stringAvatar(props.name)} >
                            {userProps.src || nameToInitials(props.name)}
                        </Avatar>
                    :
                        <Avatar>
                            <PersonIcon />
                        </Avatar>
            )}
        </>
    )
}

export default UserChatMessage;