// React
import { useEffect, useState } from "react";

// MSAL
import { msalInstance } from "../index";
import { msalDefaultLoginRequest } from "../authConfig";

// Misc
import config from "../config.json";

const acquireAccessToken = async (loginRequest = msalDefaultLoginRequest) => {
    const enableMSAL = !!config.MSAL?.auth?.clientId;

    if (!enableMSAL) {
        return;
    }

    const account = msalInstance.getActiveAccount();
    if (!account) {
        return;
    }

    const authResult = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    return authResult.accessToken;
}

const useAccessToken = () => {
    const [ accessToken, setAccessToken ] = useState(null);
    const [ graphToken, setGraphToken ] = useState(null);

    useEffect(() => {
        const acquireAllAccessTokens = async () => {
            const accessToken = await acquireAccessToken();
            const graphToken = await acquireAccessToken({ 'scopes': [ 'User.Read', 'User.ReadBasic.All' ] })
            setAccessToken(accessToken);
            setGraphToken(graphToken);
        }
        acquireAllAccessTokens();
    }, []);

    return { accessToken, graphToken };
}

export default useAccessToken;
