// React
import { useTheme } from "@emotion/react";
import { useContext } from "react";

// MUI
import { Avatar, Badge, Box, Link, styled } from "@mui/material";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { green, red } from '@mui/material/colors';

// Misc
import DOMPurify from "dompurify";
import TypingIndicator from "../components/typing-indicator";
import { Context } from "../context/context";

const AgentChatMessage = (props) => {
    const { previewDocumentContext } = useContext(Context);
    const [ , setPreviewDocument ] = previewDocumentContext;

    const agentProps = {
        alt: props.name,
        title: `${props.name} is ${props.online ? 'online' : 'offline'}`,
        src: props.avatarUrl,
    }

    const theme = useTheme();

    // list duplicate citation links only once
    const uniqueCitations = props.citations?.filter((citation, index) =>
        props.citations.findIndex(citation2 => citation.citationName === citation2.citationName) === index
    );

    const CitationLink = styled(Link)((props) => ({
        fontSize: '14px',
        float: 'left',
        clear: 'both',
        marginTop: '15px',
        background: theme.palette.primary.main,
        borderRadius: '5px',
        color: theme.palette.primary.contrastText,
        fontWeight: 700,
        padding: '4px 10px',
        textDecoration: 'none',
    }));

    const AvatarWithStatus = styled(Badge)(({ theme }) => ({
        'marginRight': '8px !important',
        ...(props.online !== undefined && {
            '& .MuiBadge-badge': {
                backgroundColor: props.online ? green[500] : red[900],
                color: props.online ? green[500] : red[900],
                boxShadow: `0 0 0 2px ${theme.palette.background.default}`,
                '&::after': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    border: '1px solid currentColor',
                    content: '""',
                },
            }
        }),
    }));

    function replaceMessageCitationsWithSuperscriptTags(text, citations) {
        citations?.forEach((citation, index) => {
            const citationPattern = '[' + citation.citationName + ']';

            const citationTag = `<sup
                style="
                    background: ${theme.palette.primary.main};
                    color: ${theme.palette.primary.contrastText};
                    font-weight: 700;
                    padding: 2px;
                    border-radius: 4px;
                    margin-left: 2px;
                    margin-right: 2px;
                "
            >${index + 1}</sup>`;

            while (text.includes(citationPattern)) {
                text = text.replace(citationPattern, citationTag);
            }
        });

        return text;
    }

    const handlePreviewDocumentClick = (e, documentFile) => {
        setPreviewDocument(documentFile);
        e.preventDefault();
    }

    return (
        <>
            {props.avatar &&
                <>
                    <AvatarWithStatus
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >
                        <Avatar {...agentProps} >
                            {agentProps.src || <SmartToyIcon />}
                        </Avatar>
                    </AvatarWithStatus>
                </>
            }
            <Box sx={{
                position: 'relative',
                bgcolor: theme.palette.primary.light,
                padding: theme.spacing(2),
                borderRadius: '0.5rem',
                alignSelf: 'flex-end',
                maxWidth: '80%',
                wordWrap: 'break-word',
                marginLeft: '0.5rem !important', // @xxx hack to fix ::before not being displayed when avatars are disabled
                '& > p': {
                    margin: 0,
                },
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    width: 0,
                    height: 0,
                    border: '0.5rem solid transparent',
                    borderRightColor: theme.palette.primary.light,
                    borderLeft: 0,
                    transform: 'translate(-100%, -' + theme.spacing(1) + ')',
                }
            }}>
                {props.typing
                    ?
                        <TypingIndicator />
                    :

                    <p
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(replaceMessageCitationsWithSuperscriptTags(props.message, props.citations))}}
                        style={{ whiteSpace: "pre-line" }}
                    >
                    </p>
                }
                {uniqueCitations?.map((citation, index) => (
                    citation.type === 'file'
                        ?
                            <CitationLink
                                key={index}
                                href={'/'}
                                onClick={(e) => handlePreviewDocumentClick(e, citation.citationName)}
                            >
                                {index + 1 + ', ' + citation.displayName}
                            </CitationLink>
                        :
                            <CitationLink
                                key={index}
                                target="_blank"
                                href={citation.displayName}
                            >
                                {index + 1 + ', ' + citation.displayName}
                            </CitationLink>
                ))}
            </Box>
        </>
    )
}

export default AgentChatMessage;