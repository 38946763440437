import axios from 'axios';
import config from "../config.json";
import useAccessToken from './use-accesstoken';
import useLanguage from './use-language';

const ApiErrorMessages= Object.freeze({
    'DEFAULT': {
        'en': 'I am sorry but something unexpected went wrong while processing your question.',
        'sv': 'Jag är ledsen, men något oväntat gick fel när din fråga behandlades.',
    },
    'ECONNABORTED': {
        'en': 'I am sorry but your question took too long to process.',
        'sv': 'Jag är ledsen, men din fråga tog för lång tid att behandla.',
    },
    'HTTP.403': {
        'en': 'I am sorry but you are not allowed to talk to me without being logged in.',
        'sv': 'Jag är ledsen, men du får inte prata med mig utan att vara inloggad.',
    },
    'HTTP.429': {
        'en': 'There are currently too many users talking to me, please try again a bit later.',
        'sv': 'Det är för närvarande för många användare som pratar med mig. Försök igen lite senare.',
    },
    'ResponsibleAIPolicyViolation': {
        'en': 'I am sorry but your question triggered our content policy and thus I cannot provide you with a response to your question.',
        'sv': 'Jag är ledsen, men din fråga utlöste vår policy om innehåll och jag kan därför inte ge dig ett svar på din fråga.',
    },
});

const useAPI = () => {
    const { accessToken } = useAccessToken();
    const { language } = useLanguage(['en', 'sv']);

    const api = axios.create({
        baseURL: config.SERVER_URL,
        timeout: 500000,
    })

    api.interceptors.request.use(
        async (config) => {
            //const accessToken = await acquireAccessToken();
            if (accessToken) {
                // console.log("access token in api interceptor: ", accessToken);
                config.headers.Authorization = "Bearer " + accessToken;
            }
            return config;
        },
        (error) => {
            console.error(error);
            return Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        (response) => response,
        (error) => {
            console.error('error.code', error.code);
            console.error('error.response.status', error.response?.status);
            console.error('error.message', error.message);
            console.error('error.response', error.response);
            console.error('error.response.data.error', error.response?.data?.error);

            let errorMsg = ApiErrorMessages.DEFAULT.en;

            if (ApiErrorMessages.DEFAULT[language])
                errorMsg = ApiErrorMessages.DEFAULT[language];

            if (ApiErrorMessages[error.code]?.[language])
                errorMsg = ApiErrorMessages[error.code]?.[language];

            if (ApiErrorMessages[error.response?.data?.code]?.[language])
                errorMsg = ApiErrorMessages[error.response?.data?.code]?.[language];

            if (ApiErrorMessages['HTTP.' + error.response?.status]?.[language])
                errorMsg = ApiErrorMessages['HTTP.' + error.response?.status]?.[language];

            return Promise.reject(errorMsg);
        }
    );

    return { api };
}

export default useAPI;
