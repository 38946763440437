// React
import { useContext, useEffect } from "react";

// MSAL
import {
    useMsal,
} from "@azure/msal-react";

// Misc
import { Context } from "../context/context";
import axios from "axios";
import useAccessToken from "../hooks/use-accesstoken";

const MsalUserInfo = () => {
    const { userInfoContext } = useContext(Context);
    const [ , setUserInfo ] = userInfoContext;

    const { instance, accounts } = useMsal();
    const { graphToken } = useAccessToken();

    useEffect(() => {
        if (!instance) {
            return;
        }

        const account = instance.getActiveAccount();
        if (!account) {
            return;
        }

        setUserInfo((prev) => {
            return {
                ...prev,
                fullName: account.name,
                username: account.username,
                // id: account.homeAccountId, // @xxx homeAccountId vs. localAccountId?
            }
        })
    }, [instance, accounts, setUserInfo]);

    useEffect(() => {
        if (!graphToken) {
            return;
        }

        const retrieveGraphPhoto = async () => {
            try {
                const { data: avatarBlob } = await axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
                    headers: { Authorization: `Bearer ${graphToken}` },
                    responseType: 'blob',
                });

                const avatarBlobUrl = window.URL.createObjectURL(avatarBlob);

                setUserInfo((prev) => {
                    return {
                        ...prev,
                        avatarUrl: avatarBlobUrl,
                    }
                })
            } catch (error) {
                if (error.response?.status === 404) {
                    console.warn('User avatar in directory not found');
                } else {
                    console.error(error);
                }

                return;
            }
        }
        retrieveGraphPhoto();

    }, [graphToken, setUserInfo]);

    return;
}

export default MsalUserInfo;
