// React
import { useTheme } from "@emotion/react";
import { useContext } from "react";

// MUI
import { Stack } from "@mui/material";

// Misc
import { Context } from "../context/context";
import UserChatMessage from "./user-chat-message";
import AgentChatMessage from "./agent-chat-message";
import MessageFeedback from "./message-feedback";
import config from "../config.json";

const ChatMessage = (props) => {
    const { userInfoContext } = useContext(Context);
    const [ userInfo ] = userInfoContext;

    const messageIndex = props.index;

    const theme = useTheme();

    return (
        <Stack
            direction="row"
            justifyContent={props.align === 'user' ? 'flex-end' : 'flex-start'}
            alignItems="center"
            spacing={1}
            my={theme.spacing(2)}
        >
            {props.align === 'user'
                ?
                    <UserChatMessage
                        name={userInfo.fullName}
                        avatarUrl={userInfo.avatarUrl}
                        message={props.message}
                        avatar={props.avatar}
                    />
                :
                    <>
                        <AgentChatMessage
                            messageIndex={messageIndex}
                            message={props.message}
                            typing={props.typing}
                            citations={props.citations}
                            name={config.AGENT?.NAME || "Agent"}
                            avatar={props.avatar}
                            avatarUrl={config.AGENT?.AVATAR_URL}
                            online={!props.error}
                        />
                        {props.enableFeedback &&
                            <MessageFeedback
                                messageIndex={messageIndex}
                            />
                        }
                    </>
            }
        </Stack>
    )
}

export default ChatMessage;
